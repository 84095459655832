<template>
  <v-container>
    <div v-if="loading">
      <v-overlay :value="loading" absolute>
        <v-progress-circular indeterminate color="primary" />
      </v-overlay>
    </div>
    <div v-else>
      <v-list>
        <v-list-group v-for="item in items" :key="item.title" v-model="item.active" :prepend-icon="item.action" no-action>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title v-text="item.title"></v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item v-for="child in item.items" :key="child.title" :to="{ name: child.route }">
            <v-list-item-content>
              <v-list-item-title v-text="child.title"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>
    </div>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'CalculatorList',
  data() {
    return {
      loading: true,
      items: [
        {
          action: 'mdi-currency-usd',
          // active: true,
          items: [{ title: 'Snowball', route: 'CalculatorSnowball' }],
          // items: [{ title: 'Breakfast & brunch' }, { title: 'New American' }, { title: 'Sushi' }],
          title: 'Financial'
        }
      ]
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.userData
    })
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      this.loading = true
      this.loading = false
    }
  }
}
</script>

<style></style>
